import * as React from 'react';

import './About.scss';

// import Adi from './assets/employeeImages/adi.png';
// import Amit from './assets/employeeImages/amit.png';
import Ari from './assets/employeeImages/ari.png';
// import Arnon from './assets/employeeImages/arnon.png';
import Baruch from './assets/employeeImages/baruch.png';
import MaayanSchiller from './assets/employeeImages/maayan-schiller.png';
import Yotam from './assets/employeeImages/yotam.png';
import Nour from './assets/employeeImages/nour.png';

import Jill from './assets/employeeImages/jill.png';
import Elana from './assets/employeeImages/elana.png';
import Chana from './assets/employeeImages/chana.png';

import TeamPhoto from './assets/hands.png';

import { EmployeeBox } from './components/EmployeeBox/EmployeeBox';

import StatisticsAnalysisDesign2 from './assets/statistics-analysis-2.svg';
import WhyVeracityAds from './assets/graphic-WhyVeracityAds.svg';
import { TitleSeparatorTextComponent } from '../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../../components/common/HorizontalSection/HorizontalSection';
import { NormalText, NormalTitle } from '../../components/common/WebsiteTextTypes';

export const About: React.FC = () => {
  const aboutVeracityAds = 'About VeracityAds';
  const aboutVeracityAdsText =
    'VeracityAds is a new and fast-growing search feeds distribution network. The company was established at the beginning of 2021 by the company founder and CEO - Yotam Elal. In a short time, VeracityAds grew from just one person to over a dozen employees.';

  const communication = 'Communication';
  const communicationText =
    'Here at VeracityAds, we take pride in our uncompromising honesty, sincerity, and respectability, in addition to aiming for the highest level of professionalism. We believe that these are the keys to establishing long-lasting partnerships and success all around.';

  const mission = 'Mission';
  const missionText =
    'Our primary goal in VeracityAds is to raise funds and use our revenue to make the world a better place. A significant portion of our profits is reserved for angel investing in companies and projects whose purpose is to solve urgent world problems, such as hunger, water shortage, etc. ';

  const ourTeamHeading = <NormalTitle>Our Team</NormalTitle>;
  const ourTeamText = (
    <NormalText>
      The VeracityAds team is an amazing group of dedicated people who have the drive and determination to help us all
      succeed together.
    </NormalText>
  );
  return (
    <div className="page">
      <div id="about-section" className="first-section">
        <HorizontalSection
          left={<TitleSeparatorTextComponent title={aboutVeracityAds} text={aboutVeracityAdsText} titleSize="large" />}
          right={<img src={TeamPhoto} alt="" />}
        />
      </div>
      <div id="container-Communication">
        <HorizontalSection
          left={<img src={StatisticsAnalysisDesign2} alt="" />}
          right={<TitleSeparatorTextComponent title={communication} text={communicationText} />}
          removeLeftOnMobileView
          ignoreLeftMargin
        />
      </div>

      <HorizontalSection
        left={<TitleSeparatorTextComponent title={mission} text={missionText} />}
        right={<img src={WhyVeracityAds} alt="" />}
      />

      <div className="section" style={{ flexDirection: 'column', marginTop: '30px' }}>
        <div>{ourTeamHeading}</div>
        <div style={{ maxWidth: '70%' }}>{ourTeamText}</div>
      </div>

      <EmployeeBox
        picture={Yotam}
        name="Yotam Elal"
        job="CEO"
        description="An innovator that has brought to life various successful products and services, including FoxLingo, the world’s first free, online translation, browser addon, SoftPublisher, which was a well-known successful, software distribution company, and VeracityAds, now one of the world’s leading search feeds distribution networks."
        facebookLink="https://www.facebook.com/yotamelal"
        linkedInLink="https://www.linkedin.com/in/yotamelal/"
        initialAlignment="Horizontal"
      />

      <div id="employees-container">
        <EmployeeBox
          picture={Baruch}
          name="Baruch Lerner"
          job="COO"
          facebookLink="https://www.facebook.com/Bobosof/"
          linkedInLink="https://www.linkedin.com/in/baruch-lerner-2b093b63/"
          initialAlignment="Vertical"
      />

          <EmployeeBox
          picture={Chana}
          name="Chana Drori"
          job="Developer"
          // facebookLink=""
          linkedInLink="https://www.linkedin.com/in/chana-drori-3b116b166/"
          initialAlignment="Vertical"
      />

        {/* <EmployeeBox
          picture={Adi}
          name="Adi Hay"
          job="Executive Assistant"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/adi-hay-300974123/"
          initialAlignment="Vertical"
        /> */}

        <EmployeeBox
          picture={Ari}
          name="Ari Moher"
          job="Account Manager"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/ari-moher-82579711b/"
          initialAlignment="Vertical"
        />

        <EmployeeBox
          picture={MaayanSchiller}
          name="Maayan Schiller"
          job="Account Manager"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/ma-ayan-schiller-0043b9205/"
          initialAlignment="Vertical"
        />

        {/* <EmployeeBox
          picture={Niv}
          name="Niv Moussan"
          job="Account Manager"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/niv-moussan"
          initialAlignment="Vertical"
        /> */}

        {/* <EmployeeBox
          picture={Amit}
          name="Amit Elal"
          job="Product Manager"
          facebookLink="https://www.facebook.com/profile.php?id=100015296508870"
          linkedInLink="https://www.linkedin.com/in/amit-elal-210845225/"
          initialAlignment="Vertical"
        /> */}

        {/* <EmployeeBox
          picture={Arnon}
          name="Arnon Elal"
          job="Front-end Developer"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/arnon-elal-0a01b515a/"
          initialAlignment="Vertical"
        /> */}

        <EmployeeBox
          picture={Nour}
          name="Nour Shahin"
          job="Developer"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/nour-shahin-5bb81622b"
          initialAlignment="Vertical"
        />

       

        <EmployeeBox
          picture={Jill}
          name="Jill Mintz"
          job="Finance Manager"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/jill-mintz-3a413073/"
          initialAlignment="Vertical"
        />

        <EmployeeBox
          picture={Elana}
          name="Elana A Lehrer"
          job="Account Manager"
          // facebookLink="facebook.com"
          linkedInLink="https://www.linkedin.com/in/elanalehrer/"
          initialAlignment="Vertical"
        />

      </div>
    </div>
  );
};
