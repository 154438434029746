import * as React from 'react';
import './VeracityAdsAndroidApps.scss';

// swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import { Autoplay, Pagination } from 'swiper';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';

import { ReactComponent as VerifactsLogo } from '../../assets/logo-Verifacts.svg';
import { ReactComponent as ShowSeekLogo } from '../../assets/logo-ShowSeek.svg';
import { ReactComponent as MovieMatchLogo } from '../../assets/logo-MovieMatch.svg';
import { NormalText, NormalTitle, SmallText, SmallTitle } from '../../../../components/common/WebsiteTextTypes';

const veracityAdsAndroidAppsText =
  'VeracityAds mobile apps provide users with verified facts, recommended TV shows, movies, and more.';

const VerifactsComponent: React.FC = () => (
  <a href="http://verifactsapp.com/">
    <div className="android-app" id="Verifacts">
      <VerifactsLogo className="android-app-logo" />
      <div className="android-app-paragraph">
        <SmallTitle>Verifacts</SmallTitle>
        <SmallText>
          Hand picked, thoroughly verified, astonishing facts, with stunning photos and videos about a multitude of
          topics.
        </SmallText>
      </div>
    </div>
  </a>
);

const ShowSeekComponent: React.FC = () => (
  <a href="https://play.google.com/store/apps/details?id=com.apperzhome.showseek">
    <div className="android-app" id="ShowSeek">
      <ShowSeekLogo className="android-app-logo" />
      <div className="android-app-paragraph">
        <SmallTitle>ShowSeek</SmallTitle>
        <SmallText>Discover the best TV shows to watch</SmallText>
      </div>
    </div>
  </a>
);

const MovieMatchComponent: React.FC = () => (
  <a href="https://play.google.com/store/apps/details?id=com.apperzhome.moviematch">
    <div className="android-app" id="MovieMatch">
      <MovieMatchLogo className="android-app-logo" />
      <div className="android-app-paragraph">
        <SmallTitle>MovieMatch</SmallTitle>
        <SmallText>Find movies that are specifically matched to your taste</SmallText>
      </div>
    </div>
  </a>
);

interface AndroidAppsParagraphProps {
  id: string;
}

const AndroidAppsParagraph: React.FC<AndroidAppsParagraphProps> = (props) => (
  <div className="android-apps-paragraph" id={props.id}>
    <NormalTitle id="header-VeracityAdsAndroidApps">VeracityAds Android Apps</NormalTitle>
    <NormalText id="text-VeracityAdsAndroidApps">{veracityAdsAndroidAppsText}</NormalText>
  </div>
);

const AndroidAppCarousel: React.FC = () => (
  <Swiper
    modules={[Pagination, Autoplay]}
    loop
    autoplay={{
      delay: 2000,
      disableOnInteraction: true,
    }}
    slidesPerView={1}
    pagination={{ clickable: true }}
    id="android-apps-carousel"
  >
    <SwiperSlide>
      <ShowSeekComponent />
    </SwiperSlide>
    <SwiperSlide>
      <MovieMatchComponent />
    </SwiperSlide>
    <SwiperSlide>
      <VerifactsComponent />
    </SwiperSlide>
  </Swiper>
);

export const VeracityAdsAndroidApps: React.FC = () => (
  <div id="container-VeracityAdsAndroidApps">
    <AndroidAppsParagraph id="android-apps-paragraph-mobile-version" />
    <div id="content-container-VeracityAdsAndroidApps">
      <div id="content-VeracityAdsAndroidApps">
        <AndroidAppCarousel />
        <AndroidAppsParagraph id="android-apps-paragraph-desktop-version" />
        <div id="android-apps-list">
          <ShowSeekComponent />
          <MovieMatchComponent />
          <VerifactsComponent />
        </div>
      </div>
    </div>
  </div>
);
