import React, { ReactNode } from 'react';

import './HorizontalSection.scss';

const CONTAINER_CLASSNAME = 'container-HorizontalSection';
const IGNORE_LEFT_MARGIN_CLASSNAME = 'ignore-margin-left';
const IGNORE_RIGHT_MARGIN_CLASSNAME = 'ignore-margin-right';
const REVERSE_COLUMN_CLASSNAME = 'reverse-column-order-on-mobile';
const SIDE_ELEMENT_CLASSNAME = 'side-element';
const RIGHT_ELEMENT = ' right-element';
const LEFT_ELEMENT = ' left-element';
const REMOVE_ELEMENT_ON_MOBILE_CLASSNAME = 'remove-on-mobile';

interface Props {
  left: ReactNode;
  right: ReactNode;
  removeLeftOnMobileView?: boolean; // hide left element when switching to narrow view (default false)
  removeRightOnMobileView?: boolean;
  ignoreLeftMargin?: boolean; // left element would stick to edge of screen, right element aligned as usual (default false)
  ignoreRightMargin?: boolean;
  reverseVerticalView?: boolean; // when switching to vertical mode, right element would be kept on top (default false)
}

// apply classNames to side element to determine which styles in scss files affect it.
function componentClassName(elementSide: string, removeOnMobile?: boolean): string {
  return SIDE_ELEMENT_CLASSNAME + (removeOnMobile ? ` ${REMOVE_ELEMENT_ON_MOBILE_CLASSNAME}` : '') + elementSide;
}

export const HorizontalSection: React.FC<Props> = (props: Props) => {
  // apply classNames to container-HorizontalSection to determine which styles in scss files affect it.
  const containerClassNames =
    CONTAINER_CLASSNAME +
    (props.ignoreLeftMargin ? ` ${IGNORE_LEFT_MARGIN_CLASSNAME}` : '') +
    (props.ignoreRightMargin ? ` ${IGNORE_RIGHT_MARGIN_CLASSNAME}` : '') +
    (props.reverseVerticalView ? ` ${REVERSE_COLUMN_CLASSNAME}` : '');

  return (
    <div className={containerClassNames}>
      <div className={componentClassName(LEFT_ELEMENT, props.removeLeftOnMobileView)}>{props.left}</div>

      <div className={componentClassName(RIGHT_ELEMENT, props.removeRightOnMobileView)}>{props.right}</div>
    </div>
  );
};
