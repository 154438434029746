import * as React from 'react';
import { SearchFeedDistribution } from './components/SearchFeedDistribution';
import { WhyChooseVeracityAds } from './components/WhyChooseVeracityAds/WhyChooseVeracityAds';
import { VeracityAdsForPublishers } from './components/VeracityAdsForPublishers';
import { VeracityAdsForAdvertisers } from './components/VeracityAdsForAdvertisers';
import { VeracityAdsAndroidApps } from './components/VeracityAdsAndroidApps/VeracityAdsAndroidApps';
import { ReactComponent as BannerHomePageFooterGraphic } from './assets/banner-home-page-footer-graphic.svg';
import { VeracityAdsChromeExtensions } from '../../components/common/VeracityAdsChromeExtensions/VeracityAdsChromeExtensions';
import { ContactUsBanner } from '../../components/common/ContactUsBanner/ContactUsBanner';

export const Home: React.FC = () => (
  <div className="page">
    <div id="SearchFeedDistribution" className="first-section">
      <SearchFeedDistribution />
    </div>

    <div id="WhyChooseVeracityAds">
      <WhyChooseVeracityAds />
    </div>

    <div id="VeracityAdsForPublishers">
      <VeracityAdsForPublishers />
    </div>

    <div id="VeracityAdsForAdvertisers">
      <VeracityAdsForAdvertisers />
    </div>

    <div
      id="VeracityAdsAndroidApps"
      style={{
        marginInlineStart: 'var(--negate-current-global-margin)',
        marginInlineEnd: 'var(--negate-current-global-margin)',
      }}
    >
      <VeracityAdsAndroidApps />
    </div>

    <div id="VeracityAdsChromeExtensions">
      <VeracityAdsChromeExtensions />
    </div>

    <ContactUsBanner />

    <BannerHomePageFooterGraphic width="50%" />
  </div>
);
