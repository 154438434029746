import * as React from 'react';
import { TitleSeparatorTextComponent } from 'components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from 'components/common/HorizontalSection/HorizontalSection';
import { NormalText, NormalTitle } from '../../components/common/WebsiteTextTypes';
import { VeracityAdsChromeExtensions } from '../../components/common/VeracityAdsChromeExtensions/VeracityAdsChromeExtensions';

import { AppSection } from './AppSection/AppSection';
import './Products.scss';

import VeracityAdsProducts from './assets/images/veracityads-products.svg';
import GooglePlay from './assets/images/google-play.svg';

import Verifacts from './assets/images/verifacts/verifacts.svg';
import VerifactsWobble from './assets/images/verifacts/verifacts-wobble.svg';
import VerifactsScreenshot1 from './assets/images/verifacts/screenshots/screenshot1.svg';
import VerifactsScreenshot2 from './assets/images/verifacts/screenshots/screenshot2.svg';
import VerifactsScreenshot3 from './assets/images/verifacts/screenshots/screenshot3.svg';
import VerifactsScreenshot4 from './assets/images/verifacts/screenshots/screenshot4.svg';

import Showseek from './assets/images/showseek/show-seek.svg';
import ShowseekWobble from './assets/images/showseek/showseek-wobble.svg';
import ShowseekScreenshot1 from './assets/images/showseek/screenshots/screenshot1.svg';
import ShowseekScreenshot2 from './assets/images/showseek/screenshots/screenshot2.svg';
import ShowseekScreenshot3 from './assets/images/showseek/screenshots/screenshot3.svg';
import ShowseekScreenshot4 from './assets/images/showseek/screenshots/screenshot4.svg';

import Moviematch from './assets/images/moviematch/movie-match.svg';
import MoviematchWobble from './assets/images/moviematch/moviematch-wobble.svg';
import MoviematchScreenshot1 from './assets/images/moviematch/screenshots/screenshot1.svg';
import MoviematchScreenshot2 from './assets/images/moviematch/screenshots/screenshot2.svg';
import MoviematchScreenshot3 from './assets/images/moviematch/screenshots/screenshot3.svg';
import MoviematchScreenshot4 from './assets/images/moviematch/screenshots/screenshot4.svg';

export const Products: React.FC = () => (
  <div className="page">
    <div className="first-section">
      <HorizontalSection
        left={
          <TitleSeparatorTextComponent
            title="VeracityAds Products"
            text="In addition to our search feeds distribution, VeracityAds develops mobile apps and browser extensions in various entertainment sectors."
            titleSize="large"
          />
        }
        right={<img src={VeracityAdsProducts} alt="" />}
      />{' '}
    </div>

    <div id="android-apps-container">
      <NormalTitle>Android Apps</NormalTitle>
      <NormalText>
        VeracityAds mobile apps provide users with verified facts, recommended TV shows, movies, and more.
      </NormalText>
    </div>

    <AppSection
      left={<img src={Verifacts} alt="" />}
      right={
        <div className="info-section">
          <NormalTitle>Verifacts</NormalTitle>
          <NormalText>
            Hand picked, thoroughly verified, astonishing facts, with stunning photos and videos about a multitude of
            topics.
          </NormalText>
          <a className="google-play-button" href="http://verifactsapp.com/">
            <img src={GooglePlay} alt="" />
          </a>
        </div>
      }
      wobble={<img src={VerifactsWobble} alt="" />}
      wobbleSide="left"
      screenshot1={<img src={VerifactsScreenshot1} alt="" />}
      screenshot2={<img src={VerifactsScreenshot2} alt="" />}
      screenshot3={<img src={VerifactsScreenshot3} alt="" />}
      screenshot4={<img src={VerifactsScreenshot4} alt="" />}
    />

    <AppSection
      left={
        <div className="info-section">
          <NormalTitle>ShowSeek</NormalTitle>
          <NormalText>Discover the best TV shows to watch.</NormalText>
          <a
            className="google-play-button"
            href="https://play.google.com/store/apps/details?id=com.apperzhome.showseek"
          >
            <img src={GooglePlay} alt="" />
          </a>
        </div>
      }
      right={<img src={Showseek} alt="" />}
      wobble={<img src={ShowseekWobble} alt="" />}
      wobbleSide="right"
      screenshot1={<img src={ShowseekScreenshot1} alt="" />}
      screenshot2={<img src={ShowseekScreenshot2} alt="" />}
      screenshot3={<img src={ShowseekScreenshot3} alt="" />}
      screenshot4={<img src={ShowseekScreenshot4} alt="" />}
    />

    <AppSection
      left={<img src={Moviematch} alt="" />}
      right={
        <div className="info-section">
          <NormalTitle>MovieMatch</NormalTitle>
          <NormalText>Easily find movies that are matched to your taste.</NormalText>
          <a
            className="google-play-button"
            href="https://play.google.com/store/apps/details?id=com.apperzhome.moviematch"
          >
            <img src={GooglePlay} alt="" />
          </a>
        </div>
      }
      wobble={<img src={MoviematchWobble} alt="" />}
      wobbleSide="left"
      screenshot1={<img src={MoviematchScreenshot1} alt="" />}
      screenshot2={<img src={MoviematchScreenshot2} alt="" />}
      screenshot3={<img src={MoviematchScreenshot3} alt="" />}
      screenshot4={<img src={MoviematchScreenshot4} alt="" />}
    />
    <VeracityAdsChromeExtensions />
  </div>
);
