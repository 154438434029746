import * as React from 'react';

import './NavigationMenuButton.scss';

interface Props {
  toggleNavigationMenuVisibility: () => void;
}

export const NavigationButton: React.FC<Props> = (props) => (
  <div id="navigation-button">
    <label>
      <input type="checkbox" id="navigation-menu-button" onClick={props.toggleNavigationMenuVisibility} />
      <span />
      <span />
      <span />
    </label>
  </div>
);
