import * as React from 'react';

import './TitleSeparatorTextComponent.scss';

import { ReactComponent as SeparationLineGreenBlue } from './Assets/separation-line-green-blue.svg';
import { LargeTitle, NormalText, NormalTitle, SmallText, SmallTitle } from '../WebsiteTextTypes';

const componentClassname = 'TitleSeparatorTextComponent';

type Size = 'small' | 'normal' | 'large';

interface TitleSeparatorTextComponentProps {
  id?: string;
  title: string;
  text: string | string[];
  titleSize?: Size;
  textSize?: Size;
}

export const TitleSeparatorTextComponent: React.FC<TitleSeparatorTextComponentProps> = (props) => {
  const stringList = Array.isArray(props.text) ? props.text : [props.text];

  const text = stringList.map((str, index) => (
    <React.Fragment key={index}>
      {props.textSize === 'small' ? <SmallText>{str}</SmallText> : <NormalText>{str}</NormalText>}
    </React.Fragment>
  ));

  let title;
  switch (props.titleSize) {
    case 'small':
      title = <SmallTitle>{props.title}</SmallTitle>;
      break;

    case 'large':
      title = <LargeTitle>{props.title}</LargeTitle>;
      break;

    default:
      title = <NormalTitle>{props.title}</NormalTitle>;
      break;
  }

  return (
    <div className={componentClassname} id={props.id}>
      <div className="title-TitleSeparatorText">{title}</div>
      <SeparationLineGreenBlue className="separator-TitleSeparatorText" />
      <div className="text-TitleSeparatorText">{text}</div>
      <div className="component-TitleSeparatorText">{props.children}</div>
    </div>
  );
};
