import * as React from 'react'

import { HorizontalSection } from 'components/common/HorizontalSection/HorizontalSection'
import { TitleSeparatorTextComponent } from 'components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent'

import CareersPage from './assets/graphic-publishers-WhyVeracityAds.png'

import './Careers.scss'
import { CareerCard } from './CareerCard/CareerCard'

export const Careers: React.FC = () => {
	// const executiveAssistant = (
	// 	<CareerCard
	// 		title="Executive Assistant"
	// 		section1Title={<h2>Responsibilities:</h2>}
	// 		section1={
	// 			<p>
	// 				✔ Support senior managers and executives with daily administrative tasks <br />
	// 				✔ HR Recruitment responsibilities, including advertising new position openings <br />
	// 				✔ Planning and scheduling company conferences, appointments, and events <br />
	// 				✔ Managing office supplies and placing necessary orders <br />
	// 			</p>
	// 		}
	// 		section2Title={<h2>Requirements:</h2>}
	// 		section2={
	// 			<p>
	// 				✔ Excellent English verbal and written communications skills <br />
	// 				✔ Good Hebrew verbal and written communication skills <br />
	// 				✔ Ability to follow procedures precisely <br />
	// 				✔ Working experience with Google Docs or Word <br />
	// 				✔ Working experience with Google Sheets or Excel <br />
	// 			</p>
	// 		}
	// 		section3Title={<h2>Preferred Experience:</h2>}
	// 		section3={
	// 			<p>
	// 				✔ Work experience as an Executive Assistant, Personal Assistant or similar role <br />
	// 				✔ Outstanding organizational and time management skills <br />
	// 				✔ Proficient with Google Sheets or Excel <br />
	// 				✔ Experience with employee recruitment <br />
	// 			</p>
	// 		}
	// 		link="https://docs.google.com/forms/d/1fRI350NssPn0AlfZMpyAp44lIbmNJCIftftzs7qcQ6I/edit"
	// 	/>
	// )

	const seniorBackEnd = (
		<CareerCard
			title="Back-End Developer"
			section1Title={<h2>Description:</h2>}
			section1={
				<p>
					We're looking for a full-time backend developer to be responsible for the continuous development of the company’s distribution system. The perfect candidate will be able to work and thrive in a fast-paced environment and learn rapidly. The position requires excellent English communication skills, the ability to adapt quickly, and a high work ethic.
				</p>
			}
			section2Title={<h2>Requirements:</h2>}
			section2={
				<p>
					✔ 3 years of experience in software development, with a strong focus on Python and Django <br />
					✔ Experience with creating and managing databases and data <br />
					✔ Organized and easily understandable clean code with documentation <br />
					✔ Excellent English written communication skills <br />
					✔ Ability to work from our office in Jerusalem <br />
					✔ Creative problem-solving skills <br />
					✔ Basic understanding of AWS or similar cloud server <br />
				</p>
			}
			section3Title={<h2>Relevant Technology:</h2>}
			section3={
				<p>
					✔ Modern JavaScript, HTML, and CSS <br />
					✔ Understanding of software design principles and architectures <br />
					✔  Experience with automation tools and continuous integration/continuous deployment (CI/CD) pipelines <br />
				</p>
			}
			link="https://forms.gle/swTobrwqXDrDotQt6"
		/>
	)

	// const seniorFrontEnd = (
	// 	<CareerCard
	// 		title="Senior Front-End Developer"
	// 		section1Title={<h2>Requirements:</h2>}
	// 		section1={
	// 			<p>
	// 				✔ Excellent ReactJS programming <br />
	// 				✔ Organized and easily understandable code <br />
	// 				✔ Creative problem-solving skills <br />
	// 				✔ Three years of working experience in front-end development <br />
	// 				✔ Ability to work full time from our offices in Jerusalem <br />
	// 			</p>
	// 		}
	// 		section2Title={<h2>Relevant Technology:</h2>}
	// 		section2={
	// 			<p>
	// 				✔ Typescript, SCSS <br />
	// 				✔ RESTful API , HTTP & web protocols <br />
	// 				✔ Material UI <br />
	// 				✔ Experience with Jira / similar team management system <br />
	// 			</p>
	// 		}
	// 		link="https://docs.google.com/forms/d/16sZRQsN7Nm2IINsH8qiyZLCIJylQtMl3IueWvqJdS44/edit"
	// 	/>
	// )

	// const seniorFullStack = (
	// 	<CareerCard
	// 		title="Senior Full-Stack Developer"
	// 		section1Title={<h2>Requirements:</h2>}
	// 		section1={
	// 			<p>
	// 				✔ Three years of working experience in full-stack development <br />
	// 				✔ Proficient in Python (At least 3 years of experience) <br />
	// 				✔ Experience with creating and managing databases and data <br />
	// 				✔ Top-notch programming skills in modern JavaScript, HTML, and CSS <br />
	// 				✔ Organized and easily understandable clean code with documentation <br />
	// 				✔ Ability to lead and design web projects <br />
	// 				✔ Excellent English written communications skills <br />
	// 				✔ Ability to work from our office in Jerusalem <br />
	// 				✔ Creative problem-solving skills <br />
	// 			</p>
	// 		}
	// 		section2Title={<h2>Preferred:</h2>}
	// 		section2={
	// 			<p>
	// 				✔ Django <br />
	// 				✔ Bootstrap <br />
	// 				✔ PostgreSQL <br />
	// 				✔ AWS <br />
	// 				✔ Software design and architecture <br />
	// 				✔ Has strong abilities in server design and system infrastructure <br />
	// 				✔ Has strong automation abilities <br />
	// 				✔ Team management experience <br />
	// 			</p>
	// 		}
	// 		link="https://docs.google.com/forms/d/1hsy5A-bcSaJ-eVuSVHw-r2rql8aGsOGR22aC1u5_fw4/edit"
	// 	/>
	// )

	
	const accountManager = (
		<CareerCard
			title="Account Manager"
			section1Title={<h2>Description:</h2>}
			section1={
				<p>
					The Account Manager’s role is to oversee a portfolio of assigned customers, <br />
					develop new business from existing clients, and actively seek new business opportunities.<br />
					The position requires excellent English communication skills, the ability to adapt quickly,<br />
					and a high work ethic. This position may include optional travel abroad. <br />
				</p>
			}

			section2Title={<h2>Responsibilities:</h2>}
			section2={
				<p>
					✔ Serve as the lead point of contact for a range of customer accounts <br />
					✔ Build and maintain strong, long-lasting client relationships <br />
					✔ Develop new business with existing clients and/or identify areas of improvement and expansion <br />
					✔ Track and verify key account metrics <br />
				</p>
			}

			section3Title={<h2>Requirements:</h2>}
			section3={
				<p>
					✔ Excellent English writing and verbal skills <br />
					✔ High work ethics <br />
					✔ Good at following instructions precisely <br />
					✔ Experience with Word or Google Docs <br />
					✔ Experience with Excel or Google Spreadsheets <br />
				</p>
			}
			
			section4Title={<h2>Preferred:</h2>}
			section4={
				<p>
					✔ Worked as an account manager or in a similar position <br />
				</p>
			}
			link="https://docs.google.com/forms/d/1XiFVVSiElY2hTkJEHK_X7KKC80Izx2QH-HQ4mh_mPAM/edit?ts=60c5b74d"
		/>
	)
	// const seniorFullStack = (
	// 	<CareerCard
	// 		title="Senior Full-Stack Developer"
	// 		section1Title={<h2>Requirements:</h2>}
	// 		section1={
	// 			<p>
	// 				✔ Excellent programming skills in ReactJS, Node, Express, SQL <br />
	// 				✔ Organized and easily understandable code <br />
	// 				✔ Creative problem-solving skills <br />
	// 				✔ Three years of working experience in full-stack development <br />
	// 				✔ Ability to work full time from our offices in Jerusalem <br />
	// 			</p>
	// 		}
	// 		section2Title={<h2>Relevant Technology:</h2>}
	// 		section2={
	// 			<p>
	// 				✔ AWS <br />
	// 				✔ NestJS <br />
	// 				✔ PostgreSQL <br />
	// 				✔ Typescript, SCSS <br />
	// 				✔ RESTful API , HTTP & web protocols <br />
	// 				✔ Material UI <br />
	// 				✔ Experience with Jira / similar team management system <br />
	// 			</p>
	// 		}
	// 		link="https://docs.google.com/forms/d/1hsy5A-bcSaJ-eVuSVHw-r2rql8aGsOGR22aC1u5_fw4/edit"
	// 	/>
	// )

	// const backEnd = (
	// 	<CareerCard
	// 		title="Back-End Developer"
	// 		section1Title={<h2>Requirements:</h2>}
	// 		section1={
	// 			<p>
	// 				✔ Excellent programming skills in Node, Express, SQL <br />
	// 				✔ Organized and easily understandable clean code <br />
	// 				✔ Creative problem-solving skills <br />
	// 				✔ Ability to work full time from our offices in Jerusalem <br />
	// 			</p>
	// 		}
	// 		section2Title={<h2>Relevant Technology:</h2>}
	// 		section2={
	// 			<p>
	// 				✔ AWS <br />
	// 				✔ NestJS <br />
	// 				✔ PostgreSQL <br />
	// 				✔ Experience with Jira / similar team management system <br />
	// 			</p>
	// 		}
	// 		link="https://docs.google.com/forms/d/1aqpXbGFALFhYpu3yyVcEu3Calw3f-mqfxV9JNiw1PJo/edit"
	// 	/>
	// )

	// const frontEnd = (
	// 	<CareerCard
	// 		title="Front-End Developer"
	// 		section1Title={<h2>Requirements:</h2>}
	// 		section1={
	// 			<p>
	// 				✔ Excellent ReactJS programming <br />
	// 				✔ Organized and easily understandable code <br />
	// 				✔ Creative problem-solving skills <br />
	// 				✔ Ability to work full time from our offices in Jerusalem <br />
	// 			</p>
	// 		}
	// 		section2Title={<h2>Relevant Technology:</h2>}
	// 		section2={
	// 			<p>
	// 				✔ Typescript, SCSS <br />
	// 				✔ RESTful API , HTTP & web protocols <br />
	// 				✔ Material UI <br />
	// 				✔ Experience with Jira / similar team management system <br />
	// 			</p>
	// 		}
	// 		link="https://docs.google.com/forms/d/16sZRQsN7Nm2IINsH8qiyZLCIJylQtMl3IueWvqJdS44/edit"
	// 	/>
	// )

	return (
		<div className="page">
			<div className="first-section">
				<HorizontalSection
					left={
						<TitleSeparatorTextComponent
							title="Veracity Careers"
							text={[
								'We are looking for talented people to join our team. VeracityAds is an ad network located in Ein Karem, Jerusalem. This is an opportunity to join our company in its early stages, and to be a significant part of its growth. The positions below are all full-time and the work is done in the office. The ideal candidate will have the ability to work and thrive in a fast-paced environment, to learn rapidly, and to master diverse technologies.',
								'If you would like to have a greater impact on your surroundings, contribute to a better future, and develop your career, join the VeracityAds family, and help us get to our goal of helping the world become a better place.',
							]}
							titleSize="large"
						>
							<p></p>

							<p></p>
						</TitleSeparatorTextComponent>
					}
					right={<img src={CareersPage} />}
				></HorizontalSection>
			</div>

			<div id="careers-section">
				{/* {executiveAssistant} */}
				{seniorBackEnd}
				{/* {seniorFrontEnd} */}
				{/* {seniorFullStack} */}
				{accountManager}
				{/* {backEnd} */}
				{/* {frontEnd} */}
			</div>
		</div>
	)
}
