import * as React from 'react';

import './TextImageButton.scss';

import { ReactComponent as ApplyButtonImage } from './images/apply.svg';

interface Props {
  link?: string;
}

export const ApplyButton: React.FC<Props> = (props) => (
  <a href={props.link}>
    <ApplyButtonImage className="text-image-button" width="100%" />
  </a>
);
