import * as React from 'react';

import { OurKeyAdvantages } from './components/OurKeyAdvantages/OurKeyAdvantages';
import { WhyVeracityAds } from './components/WhyVeracityAds';
import { DistributeYourSearchFeeds } from './components/DistributeYourSearchFeeds';
import { SignUpTodayBanner } from '../../components/common/SignUpTodayBanner/SignUpTodayBanner';

const signUpTodayText =
  'Open a new revenue stream by connecting your search feeds to our network. Enjoy our organized and transparent approach.';

export const Advertisers: React.FC = () => (
  <div className="page" id="advertisers">
    <div id="DistributeYourSearchFeeds" className="first-section">
      <DistributeYourSearchFeeds />
    </div>
    <div id="WhyVeracityAds">
      <WhyVeracityAds />
    </div>
    <div id="WhyVeracityAdsKeyReasons">
      <OurKeyAdvantages />
    </div>
    <div id="SignUpAsAnAdvertiserToday">
      <SignUpTodayBanner title="Sign up as an Advertiser Today" text={signUpTodayText} />
    </div>
  </div>
);
