import * as React from 'react';

import './SignUpTodayBanner.scss';

import { ReactComponent as SignUpNowButtonImage } from '../buttons/images/sign-up-now-button.svg';
import { ReactComponent as SignUpBarGraphic } from './assets/graphic-sign-up-bar.svg';
import { ReactComponent as GreenBlueCornerElement } from './assets/element-GreenBlueCorner.svg';
import { ReactComponent as LinedCircleElement } from './assets/element-lined-circle.svg';
import { TitleSeparatorTextComponent } from '../TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../HorizontalSection/HorizontalSection';
import { SignupModal } from '../SignupPopup/SignupPopup';

interface SignUpTodayProps {
  title: string;
  text: string;
}

const ParagraphSignUp: React.FC<SignUpTodayProps> = (props) => (
  <TitleSeparatorTextComponent title={props.title} text={props.text}>
    <SignupModal>
      <SignUpNowButtonImage width="100%" className="text-image-button" />
    </SignupModal>
  </TitleSeparatorTextComponent>
);

export const SignUpTodayBanner: React.FC<SignUpTodayProps> = (props) => (
  <div className="section" id="container-SignUpTodayBanner">
    <div id="bar-SignUpTodayBanner">
      <HorizontalSection
        left={<SignUpBarGraphic width="100%" />}
        right={<ParagraphSignUp text={props.text} title={props.title} />}
        reverseVerticalView
        removeLeftOnMobileView
      />
      <GreenBlueCornerElement id="element-GreenBlueCorner" />
      <LinedCircleElement id="element-lined-circle" />
    </div>
  </div>
);
