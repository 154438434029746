import * as React from 'react';

import './EmployeeBox.scss';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';

import { Avatar } from '@mui/material';
import { NormalText, NormalTitle, SmallTitle } from '../../../../components/common/WebsiteTextTypes';

type Alignment = 'Vertical' | 'Horizontal';

interface Props {
  picture: string;
  name: string;
  job: string;
  description?: string;
  facebookLink?: string;
  linkedInLink: string;
  initialAlignment: Alignment;
}

const avatarStyle = { width: '40px', height: '40px', bgcolor: 'secondary.main' };

export const EmployeeBox: React.FC<Props> = (props) => (
  <div
    className={props.initialAlignment === 'Vertical' ? 'vertical-employee-container' : 'horizontal-employee-container'}
  >
    <div id="content-container">
      <img src={props.picture} alt="" />
      {props.initialAlignment === 'Vertical' ? (
        <SmallTitle>{props.name}</SmallTitle>
      ) : (
        <NormalTitle>{props.name}</NormalTitle>
      )}

      <h4>{props.job}</h4>

      {props.description && <NormalText>{props.description}</NormalText>}
      <div className="social-buttons-container">
        {props.facebookLink && (
          <a href={props.facebookLink} className="social-media-button">
            <Avatar sx={avatarStyle}>
              <FacebookRoundedIcon fontSize="large" />
            </Avatar>
          </a>
        )}
        <a href={props.linkedInLink} className="social-media-button">
          <Avatar sx={avatarStyle}>
            <LinkedInIcon fontSize="large" />
          </Avatar>
        </a>
      </div>
    </div>
  </div>
);
