import * as React from 'react';

import { ContactUsSignUpCombo } from 'components/common/buttons/ContactUsSignUpCombo/ContactUsSignUpCombo';
import { LearnMoreButton } from 'components/common/buttons/LearnMoreButton';
import MonetizeWithSearchFeedsGraphic from './assets/graphic-MonetizeWithSearchFeeds.png';
import PublishersWhyVeracityAdsGraphic from './assets/graphic-publishers-WhyVeracityAds.png';
import { HorizontalSection } from '../../components/common/HorizontalSection/HorizontalSection';
import { TitleSeparatorTextComponent } from '../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { SignUpTodayBanner } from '../../components/common/SignUpTodayBanner/SignUpTodayBanner';
import { BoostGrowthAtEveryStage } from './components/BoostGrowthatEveryStage/BoostGrowthAtEveryStage';

const signUpTodayText = 'Within a few days you can start generating revenue with our premium search feeds.';

const ParagraphMonetizeWithSearchFeeds: React.FC = () => (
  <TitleSeparatorTextComponent
    title="Monetize with Premium Search Feeds"
    text="Distribute our Bing and Yahoo hosted, and non-branded, search feeds for desktop and mobile. Sign up to start generating revenue, or contact us to find out more."
    titleSize="large"
  >
    <ContactUsSignUpCombo />
  </TitleSeparatorTextComponent>
);

const ParagraphPublishersWhyVeracityAds: React.FC = () => (
  <TitleSeparatorTextComponent
    title="Why VeracityAds?"
    text="VeracityAds is a new, innovative, fast-growing search feeds distribution network. With our unique methods, we get excellent results, providing quality feeds to our publishers."
  >
    <LearnMoreButton link="about" />
  </TitleSeparatorTextComponent>
);

export const Publishers: React.FC = () => (
  <div className="page" id="Publishers">
    <div id="container-MonetizeWithSearchFeeds" className="first-section" style={{ marginTop: '3vh' }}>
      <HorizontalSection
        left={<ParagraphMonetizeWithSearchFeeds />}
        right={<img src={MonetizeWithSearchFeedsGraphic} width="100%" />}
      />
    </div>
    <div id="container-PublishersWhyVeracityAds">
      <HorizontalSection
        left={<img src={PublishersWhyVeracityAdsGraphic} width="100%" />}
        right={<ParagraphPublishersWhyVeracityAds />}
        reverseVerticalView
      />
    </div>
    <div id="container-BoostGrowthAtEveryStage">
      <h2>Boost Growth at Every Stage</h2>
      <BoostGrowthAtEveryStage />
    </div>
    <div id="SignUpAsAnPublisherToday">
      <SignUpTodayBanner title="Sign up as a Publisher Today" text={signUpTodayText} />
    </div>
  </div>
);
