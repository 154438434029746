import * as React from 'react';

import { NavigationButton } from './components/NavigationMenuButton/NavigationMenuButton';
import { NavigationMenu } from './components/NavigationMenu/NavigationMenu';

import VeracityAdsLogo from '../../assets/veracityads-logo.svg';

import './NavigationBar.scss';

import { NavigationLinks } from './components/NavigationLinks/NavigationLinks';

export const NavigationBar: React.FC = () => {
  const [navigationBarClass, setNavigationBarClass] = React.useState<string>('navigation-bar-unscrolled');

  window.addEventListener('scroll', () => {
    const scrollPosition = document.documentElement.scrollTop;
    if (scrollPosition >= 0 && scrollPosition < 30) {
      setNavigationBarClass('navigation-bar-unscrolled');
    } else {
      setNavigationBarClass('navigation-bar-scrolled');
    }
  });

  const [menuStatus, setMenuStatus] = React.useState<'menu-open' | 'menu-closed'>('menu-closed');

  function toggleNavigationMenuVisibility() {
    if (menuStatus === 'menu-open') {
      setMenuStatus('menu-closed');
    } else {
      setMenuStatus('menu-open');
    }
  }

  const navigationBarClassNames = `${navigationBarClass} ${menuStatus}`;

  return (
    <nav id="navigation-bar" className={navigationBarClassNames}>
      <div id="main-content-container">
        <a id="veracityads-logo-link" href="/">
          <img src={VeracityAdsLogo} alt="VeracityAds" />
        </a>
        <NavigationLinks />
        <NavigationButton toggleNavigationMenuVisibility={toggleNavigationMenuVisibility} />
      </div>
      {menuStatus === 'menu-open' && <NavigationMenu />}
    </nav>
  );
};
