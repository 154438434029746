import { createTheme } from '@mui/material/styles';
import * as React from 'react';

const initialTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1024,
    },
  },
  palette: {
    primary: {
      light: '#60f9a0',
      main: '#0fc571',
      dark: '#009344',
      contrastText: '#fff',
    },
    secondary: {
      light: '#70dcf9',
      main: '#33aac6',
      dark: '#007b95',
      contrastText: '#fff',
    },
  },
  typography: {
    button: {
      whiteSpace: 'nowrap',
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        width: { mobile: '65vw', tablet: '30vw' },
        maxWidth: { mobile: '100%', tablet: '100%' },
        margin: '0 10px',
      },
    },
    MuiTextField: {
      defaultProps: {
        style: {
          width: '100%',
          fontFamily: 'Aviner',
        },
      },
    },
  },
});

export const theme = createTheme(initialTheme, {
  typography: {
    successMessage: {
      color: initialTheme.palette.success.main,
      fontFamily: 'Avenir',
    },
    errorMessage: {
      color: initialTheme.palette.error.main,
      fontFamily: 'Avenir',
    },
  },
});

// **********************************************************************
// make typescript accept our custom changes
// **********************************************************************
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the custom breakpoint
    tablet: true;
    desktop: true;
  }

  interface TypographyVariants {
    successMessage: React.CSSProperties;
    errorMessage: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    successMessage?: React.CSSProperties;
    errorMessage?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    successMessage: true;
    errorMessage: true;
  }
}
