import * as React from 'react';
import { ReactNode } from 'react';

import './SquareTile.scss';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { TitleSeparatorTextComponent } from '../TitleSeparatorTextComponent/TitleSeparatorTextComponent';

interface Props {
  id?: string;
  graphic?: ReactNode;
  title?: string;
  text?: string;
  isGreen?: boolean;
}

export const SquareTile: React.FC<Props> = (props: Props) => {
  const colorClassName = props.isGreen ? ' veracityGreen' : '';

  return (
    <div id={props.id} className="container-SquareTile">
      <div className="square-content-wrapper">
        <div id="graphic-SquareTile">{props.graphic}</div>
        <div className={`bottom-bar-SquareTile${colorClassName}`}>
          <div className="paragraph-SquareTile">
            <TitleSeparatorTextComponent
              title={props.title || ''}
              text={props.text || ''}
              titleSize="small"
              textSize="small"
            />
          </div>
          <ArrowCircleRightRoundedIcon sx={{ fontSize: '4rem', color: 'black', opacity: '50%' }} />
        </div>
      </div>
    </div>
  );
};
