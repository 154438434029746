import * as React from 'react';
import { ContactUsBanner } from '../../components/common/ContactUsBanner/ContactUsBanner';

import './Contact.scss';

export const Contact: React.FC = () => (
  <div id="contact-page" className="first-section">
    <ContactUsBanner />
  </div>
);
