import * as React from 'react';

import './OurKeyAdvantages.scss';

import { IconTitleText } from 'components/common/IconTitleText/IconTitleText';

import { ReactComponent as IconDiversityOfSearchFeeds } from './assets/icon-diversity-of-search-feeds.svg';
import { ReactComponent as IconEasyCampaignManagement } from './assets/icon-easy-campaign-management.svg';
import { ReactComponent as IconAutomatedDailyReports } from './assets/icon-automated-daily-reports.svg';
import { ReactComponent as IconQuickResponse } from './assets/icon-quick-response.svg';

export const OurKeyAdvantages: React.FC = () => (
  <div className="section" id="container-OurKeyAdvantages">
    <div id="grid-OurKeyAdvantages">
      <IconTitleText
        title="Diversity of Publishers"
        text="We provide various types of traffic including organic type-in, D2S, N2S, S2S, and other forms of traffic suitable for search feeds."
      >
        <IconDiversityOfSearchFeeds width="100%" />
      </IconTitleText>

      <IconTitleText
        title="Easy Campaign Management"
        text="Your campaign manager will help you choose from among our many traffic sources, and will faithfully keep track of each campaign's status."
      >
        <IconEasyCampaignManagement width="100%" />
      </IconTitleText>

      <IconTitleText
        title="Full Transparency"
        text="We will provide all the information you need to enable management of our traffic sources as though they were your own."
      >
        <IconAutomatedDailyReports width="100%" />
      </IconTitleText>

      <IconTitleText
        title="Quick Response"
        text="Our dedicated team is available to help our cooperation flourish and expand over time."
      >
        <IconQuickResponse width="100%" />
      </IconTitleText>
    </div>
  </div>
);
