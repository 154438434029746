import React, { ReactNode } from 'react';

import { ApplyButton } from 'components/common/buttons/ApplyButton';

import './CareerCard.scss';

interface Props {
  title: string;

  section1Title?: ReactNode;
  section1?: ReactNode;

  section2Title?: ReactNode;
  section2?: ReactNode;

  section3Title?: ReactNode;
  section3?: ReactNode;

  section4Title?: ReactNode;
  section4?: ReactNode;

  link: string;
}

export const CareerCard: React.FC<Props> = (props) => (
  <div className="career-card">
    <h1>{props.title}</h1>

    {props.section1Title}
    {props.section1}

    {props.section2Title}
    {props.section2}

    {props.section3Title}
    {props.section3}

    {props.section4Title}
    {props.section4}

    <div className="career-apply-button-container">
      <ApplyButton link={props.link} />
    </div>
  </div>
);
