import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MapIcon from '@mui/icons-material/Map';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export const ContactInfo: React.FC = () => (
  <List
    sx={{
      width: '100%',
      maxWidth: 360,
      '* > .MuiAvatar-circular': { bgcolor: '#33AAC6' },
    }}
    id="veracityads-contact-info"
  >
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <MapIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Address:" secondary="Simtat HaYekev 4, Jerusalem, Israel, 9574762" />
    </ListItem>
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <EmailOutlinedIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Email:" secondary="contact@veracityads.com" />
    </ListItem>
  </List>
);
