import * as React from 'react';
import { NavigationLinks } from '../NavigationLinks/NavigationLinks';

import './NavigationMenu.scss';

export const NavigationMenu: React.FC = () => (
  <div id="navigation-menu">
    <NavigationLinks />
  </div>
);
