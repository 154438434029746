import React from 'react';

interface Props {
  id?: string;
}

export const LargeTitle: React.FC<Props> = (props) => (
  <h1 id={props.id} className="large-title">
    {props.children}
  </h1>
);

export const NormalTitle: React.FC<Props> = (props) => (
  <h2 id={props.id} className="normal-title">
    {props.children}
  </h2>
);

export const SmallTitle: React.FC<Props> = (props) => (
  <h4 id={props.id} className="small-title">
    {props.children}
  </h4>
);

export const NormalText: React.FC<Props> = (props) => (
  <p id={props.id} className="normal-text">
    {props.children}
  </p>
);

export const SmallText: React.FC<Props> = (props) => (
  <p id={props.id} className="small-text">
    {props.children}
  </p>
);
