import * as React from 'react';

import './VeracityAdsChromeExtensions.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { HorizontalSection } from '../HorizontalSection/HorizontalSection';

import { ReactComponent as ChromeExtensionsBackground } from '../../../pages/Home/assets/background-element-chrome-extentions.svg';
import { ReactComponent as DottedSquareElement } from '../../../assets/element-dot-square.svg';
import { ReactComponent as ChromeExtensionFlixer } from '../../../pages/Home/assets/ChromeExtensionFlixer.svg';
import { ReactComponent as ChromeExtensionNewTabRandomizer } from '../../../pages/Home/assets/ChromeExtensionNewTabRandomizer.svg';
import { SquareTile } from '../SquareTile/SquareTile';
import { NormalText, NormalTitle } from '../WebsiteTextTypes';

// todo - replace with final text:
const veracityAdsChromeExtensionsText =
  'Browser extensions that provide additional functionality for Netflix and for New Tab';

const TileChromeExtensionFlixer: React.FC = () => (
  <a href="https://chrome.google.com/webstore/detail/netflix-filter-flixer/pkldnahppnkkkhbgaleaheiagoidimek?hl=en">
    <SquareTile graphic={<ChromeExtensionFlixer width="100%" />} title="Flixer" text="Find the best Netflix content" />
  </a>
);

const TileChromeExtensionNewTabRandomizer: React.FC = () => (
  <a href="https://chrome.google.com/webstore/detail/new-tab-randomizer/acpfgnibllpmfdiimfeimgdapalbihgn?hl=en">
    <SquareTile
      graphic={<ChromeExtensionNewTabRandomizer width="100%" />}
      title="New Tab Randomizer"
      text="Upgrades your new tabs with random wallpapers"
      isGreen
    />
  </a>
);
const ChromeExtensionsCarousel: React.FC = () => (
  <Swiper
    modules={[Pagination, Autoplay]}
    loop
    autoplay={{
      delay: 3000,
      disableOnInteraction: true,
    }}
    slidesPerView={1}
    pagination={{ clickable: true }}
    id="chrome-extensions-carousel"
  >
    <SwiperSlide>
      <TileChromeExtensionFlixer />
    </SwiperSlide>
    <SwiperSlide>
      <TileChromeExtensionNewTabRandomizer />
    </SwiperSlide>
  </Swiper>
);

export const VeracityAdsChromeExtensions: React.FC = () => (
  <div id="container-VeracityAdsChromeExtensions">
    <div className="chrome-extensions-top-text">
      <NormalTitle>VeracityAds Chrome Extensions</NormalTitle>
      <NormalText>{veracityAdsChromeExtensionsText}</NormalText>
    </div>

    <div id="extensions-VeracityAdsChromeExtensions">
      <div id="chrome-extensions-list">
        <HorizontalSection left={<TileChromeExtensionFlixer />} right={<TileChromeExtensionNewTabRandomizer />} />
      </div>

      <ChromeExtensionsCarousel />

      <div id="background-ChromeExtensions">
        <ChromeExtensionsBackground width="100%" />
      </div>
      <div id="element-dotted-square-ChromeExtensions">
        <DottedSquareElement width="100%" />
      </div>
    </div>
  </div>
);
