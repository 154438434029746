import React, { ReactNode, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number-2';

const companyTypes = [
  { value: 'advertiser', label: 'an advertiser' },
  { value: 'publisher', label: 'a publisher' },
  { value: 'both', label: 'an advertiser and publisher' },
];
const companyTypeNames = [...companyTypes.map((entry) => entry.value)];
type CompanyType = typeof companyTypeNames[number];

interface IContactUsForm {
  fullName: string;
  email: string;
  phone: string;
  message: string;
}

interface ISignupForm {
  company: string;
  companyType: CompanyType;
  email: string;
  phone: string;
}

interface IFormInput extends ISignupForm, IContactUsForm {}

interface IFormStatus {
  message: string;
  type: string;
}

interface IFormStatusProps {
  [key: string]: IFormStatus;
}

const formStatusProps: IFormStatusProps = {
  success: { message: 'Message sent.', type: 'success' },
  error: { message: 'Something went wrong. Please try again.', type: 'error' },
};

const baseValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
});

const contactValidationSchema = {
  fullName: Yup.string()
    .min(2, 'Please enter a valid name')
    .max(50, 'Max 50 characters')
    .required('Please enter full name'),
  message: Yup.string()
    .min(2, 'Please enter a valid message')
    .max(500, 'Max 50 characters')
    .required('Please enter a message'),
};

const signupValidationSchema = {
  company: Yup.string()
    .min(2, 'Please enter a valid company name')
    .max(100, 'Max 100 characters')
    .required("Please enter your company's name"),
  companyType: Yup.string().test((value) => typeof value === 'string' && companyTypeNames.includes(value)),
};

interface FormProps {
  type?: 'signup' | 'contact';
  submitButton: ReactNode;
  closePopupOnSubmit?: () => void;
}

export const VeracityAdsForm: React.FC<FormProps> = (formProps) => {
  const [displayFormStatus, setDisplayFormStatus] = useState(false);
  const [formStatus, setFormStatus] = useState<IFormStatus>({
    message: '',
    type: '',
  });

  const hideOnSignupForm = { display: formProps.type === 'signup' ? 'none' : 'auto' };
  const hideOnContactForm = { display: formProps.type === 'contact' ? 'none' : 'auto' };

  const formValidationSchema = baseValidationSchema.shape(
    formProps.type === 'contact' ? contactValidationSchema : signupValidationSchema,
  );

  const postFormContent = async (values: IFormInput) => {
    try {
      const requestUrl = 'https://tlxzh1ljga.execute-api.us-west-2.amazonaws.com/beta/contact-us';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        crossDomain: true,
        body: JSON.stringify(values),
      };
      const response = await fetch(requestUrl, requestOptions);
      setFormStatus(response.ok ? formStatusProps.success : formStatusProps.error);
    } catch {
      setFormStatus(formStatusProps.error);
    } finally {
      setDisplayFormStatus(true);
    }
  };

  const onFormSubmit = async (values: IFormInput, actions: FormikHelpers<IFormInput>) => {
    actions.setSubmitting(true);
    await postFormContent(values);
    setTimeout(actions.setSubmitting, 500, false);

    if (formProps.closePopupOnSubmit !== undefined) {
      formProps.closePopupOnSubmit();
    }
  };

  const FormTemplate: React.FC<FormikProps<IFormInput>> = (props) => {
    const { submitForm, setFieldValue, values, touched, errors, handleBlur, handleChange, isSubmitting } = props;
    return (
      <Form id="contact-form">
        <Grid
          container
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', maxWidth: '600px' }}
        >
          <Grid item sx={hideOnSignupForm}>
            <TextField
              name="fullName"
              id="fullName"
              label="Full Name"
              value={values.fullName}
              type="text"
              helperText={errors.fullName && touched.fullName ? errors.fullName : ''}
              error={!!(errors.fullName && touched.fullName)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item sx={hideOnContactForm}>
            <TextField
              name="company"
              id="company"
              label="Company"
              value={values.company}
              type="text"
              helperText={errors.company && touched.company ? errors.company : ''}
              error={!!(errors.company && touched.company)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item>
            <TextField
              name="email"
              id="email"
              label="Email"
              value={values.email}
              type="email"
              helperText={errors.email && touched.email ? errors.email : ''}
              error={!!(errors.email && touched.email)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item sx={hideOnContactForm}>
            <TextField
              name="companyType"
              id="outlined-select-native"
              select
              label="We are "
              value={values.companyType}
              helperText={errors.companyType && touched.companyType ? errors.companyType : ''}
              error={!!(errors.companyType && touched.companyType)}
              onChange={handleChange}
              onBlur={handleBlur}
              margin="dense"
              SelectProps={{ native: true }}
            >
              {companyTypes.map((entry) => (
                <option key={entry.value} value={entry.value}>
                  {entry.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <MuiPhoneNumber
              defaultCountry={'us'}
              name="phone"
              id="phone"
              label="phone"
              value={values.phone}
              type="phone"
              helperText={errors.phone && touched.phone ? errors.phone : ''}
              error={!!(errors.phone && touched.phone)}
              onChange={(e) => setFieldValue('phone', e)}
              onBlur={handleBlur}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item sx={hideOnSignupForm}>
            <TextField
              name="message"
              id="message"
              label="Message"
              value={values.message}
              type="message"
              helperText={errors.message && touched.message ? errors.message : ''}
              error={!!(errors.message && touched.message)}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              multiline
              minRows={2}
              margin="dense"
            />
          </Grid>

          <Grid item sx={{ margin: '10px', display: 'flex', justifyContent: 'center' }}>
            <button
              type="submit"
              onClick={async () => {
                await submitForm();
              }}
              disabled={isSubmitting}
              style={{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent' }}
            >
              {formProps.submitButton}
            </button>
            <div>
              {displayFormStatus && (
                <div className="formStatus">
                  {formStatus.type === 'error' ? (
                    <Typography variant="errorMessage">{formStatus.message}</Typography>
                  ) : formStatus.type === 'success' ? (
                    <Typography variant="successMessage">{formStatus.message}</Typography>
                  ) : undefined}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Form>
    );
  };

  return (
    <div id="form-wrapper">
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          phone: '',
          message: '',
          company: '',
          companyType: companyTypeNames[0],
        }}
        onSubmit={onFormSubmit}
        validationSchema={formValidationSchema}
      >
        {FormTemplate}
      </Formik>
    </div>
  );
};
