import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactComponent as SignUpButtonImage } from '../buttons/images/sign-up-button.svg';
import { VeracityAdsForm } from '../VeracityAdsForm/VeracityAdsForm';
import { NormalTitle } from '../WebsiteTextTypes';

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

export const SignupModal: React.FC = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div id="signup-button-wrapper" onClick={handleOpen} style={{ cursor: 'pointer' }}>
        {props.children}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Signup"
        aria-describedby="Sign up to VeracityAds as a publisher or advertiser"
      >
        <Box sx={style}>
          <NormalTitle>Sign Up</NormalTitle>
          <VeracityAdsForm
            type="signup"
            submitButton={<SignUpButtonImage width="100%" className="text-image-button" />}
            closePopupOnSubmit={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};
