import * as React from 'react';

import { ContactUsSignUpCombo } from 'components/common/buttons/ContactUsSignUpCombo/ContactUsSignUpCombo';
import { TitleSeparatorTextComponent } from '../../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../../../components/common/HorizontalSection/HorizontalSection';

import { ReactComponent as SearchFeedDistributionGraphic } from '../assets/graphic-SearchFeedsDistribution.svg';

const searchFeedDistributionText =
  'VeracityAds distributes premium Bing, Yahoo, and non-branded search feeds. Contact us or sign up to start ' +
  'generating revenue with our search feeds, or by providing us with your feeds.';

const ParagraphSearchFeedDistribution: React.FC = () => (
  <TitleSeparatorTextComponent
    title="Search Feed Distribution"
    text={searchFeedDistributionText}
    id="ParagraphSearchFeedDistribution"
    titleSize="large"
  >
    <ContactUsSignUpCombo />
  </TitleSeparatorTextComponent>
);

export const SearchFeedDistribution: React.FC = () => (
  <HorizontalSection
    left={<ParagraphSearchFeedDistribution />}
    right={<SearchFeedDistributionGraphic width="100%" />}
  />
);
