import * as React from 'react';

import { LearnMoreButton } from 'components/common/buttons/LearnMoreButton';
import { ReactComponent as WhyVeracityAdsGraphic } from '../assets/graphic-WhyVeracityAds.svg';

import { TitleSeparatorTextComponent } from '../../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../../../components/common/HorizontalSection/HorizontalSection';

const whyVeracityAdsText =
  'We will go the extra mile, doing anything within our power to give your business and products the best exposure in the market. Our network contains thousands of large audience websites, and we take pride in our ability to provide the best profit-driven results. ';

export const WhyVeracityAds: React.FC = () => (
  <div id="container-WhyVeracityAds">
    <HorizontalSection
      left={<WhyVeracityAdsGraphic width="100%" />}
      right={
        <TitleSeparatorTextComponent title="Why VeracityAds?" text={whyVeracityAdsText}>
          <LearnMoreButton link="about" />
        </TitleSeparatorTextComponent>
      }
      ignoreLeftMargin
      removeLeftOnMobileView
    />
  </div>
);
