import * as React from 'react';

import { ReactComponent as ButtonContactUs } from '../images/button-contact-us.svg';
import { ReactComponent as ButtonSignUp } from '../images/button-sign-up.svg';
import { SignupModal } from '../../SignupPopup/SignupPopup';

export const ContactUsSignUpCombo: React.FC = () => (
  <div id="container-ContactUsSignUpCombo">
    <div id="button-ContactUs" className="text-image-button">
      <a href="contact">
        <ButtonContactUs />
      </a>
    </div>
    <div id="button-SignUp" className="text-image-button">
      <SignupModal>
        <ButtonSignUp />
      </SignupModal>
    </div>
  </div>
);
