import * as React from 'react';

import './TextImageButton.scss';

import { ReactComponent as LearnMoreButtonImage } from './images/learn-more-button.svg';

interface Props {
  link?: string;
}

export const LearnMoreButton: React.FC<Props> = (props) => (
  <a href={props.link}>
    <LearnMoreButtonImage className="text-image-button" width="100%" />
  </a>
);
