import * as React from 'react';

import './IconTitleText.scss';
import { SmallText, SmallTitle } from '../WebsiteTextTypes';

interface Props {
  title: string;
  text: string;
}

export const IconTitleText: React.FC<Props> = (props) => (
  <div className="IconTitleText">
    <div className="icon-IconTitleText">
      {props.children} {/* icon svg component */}
    </div>
    <div className="title-IconTitleText">
      <SmallTitle>{props.title}</SmallTitle>
    </div>
    <div className="text-IconTitleText">
      <SmallText>{props.text}</SmallText>
    </div>
  </div>
);
