import * as React from 'react';
import { ReactNode } from 'react';

import './AppSection.scss';

import { HorizontalSection } from 'components/common/HorizontalSection/HorizontalSection';

type WobbleSize = 'left' | 'right';

interface Props {
  left: ReactNode;
  right: ReactNode;
  wobble: ReactNode;
  wobbleSide: WobbleSize;
  screenshot1?: ReactNode;
  screenshot2?: ReactNode;
  screenshot3?: ReactNode;
  screenshot4?: ReactNode;
}

export const AppSection: React.FC<Props> = (props) => {
  function reverseVerticalView() {
    if (props.wobbleSide === 'left') {
      return false;
    }
    return true;
  }

  return (
    <div className="app-section">
      <div className={props.wobbleSide === 'left' ? 'left-wobble wobble' : 'right-wobble wobble'}>{props.wobble}</div>
      <HorizontalSection left={props.left} right={props.right} reverseVerticalView={reverseVerticalView()} />
      <div className="screenshots-section">
        <div>{props.screenshot1}</div>
        <div>{props.screenshot2}</div>
        <div>{props.screenshot3}</div>
        <div>{props.screenshot4}</div>
      </div>
    </div>
  );
};
