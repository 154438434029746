import * as React from 'react';

import { LearnMoreButton } from 'components/common/buttons/LearnMoreButton';
import { TitleSeparatorTextComponent } from '../../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../../../components/common/HorizontalSection/HorizontalSection';

import { ReactComponent as VeracityAdsForPublishersGraphic } from '../assets/graphic-VeracityAdsForPublishers.svg';

const veracityAdsForPublishersText =
  'Publishers will benefit from a diversity of premium search feeds including Bing and Yahoo hosted feeds, non-branded arbitrage feeds, mobile, worldwide, and more.';

const ParagraphVeracityAdsForPublishers: React.FC = () => (
  <TitleSeparatorTextComponent title="VeracityAds For Publishers" text={veracityAdsForPublishersText}>
    <div id="learn-more-button-wrapper">
      <LearnMoreButton link="publishers" />
    </div>
  </TitleSeparatorTextComponent>
);

export const VeracityAdsForPublishers: React.FC = () => (
  <div id="container-VeracityAdsForPublishers">
    <HorizontalSection
      left={<ParagraphVeracityAdsForPublishers />}
      right={<VeracityAdsForPublishersGraphic width="100%" />}
    />
  </div>
);
