import * as React from 'react';

import { LearnMoreButton } from 'components/common/buttons/LearnMoreButton';
import { TitleSeparatorTextComponent } from '../../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../../../components/common/HorizontalSection/HorizontalSection';

import { ReactComponent as VeracityAdsForAdvertisersGraphic } from '../assets/graphic-VeracityAdsForAdvertisers.svg';

// todo - replace with final text:
const veracityAdsForAdvertisersText =
  'Advertisers can choose from many, diverse types of publishers and traffic sources through which to distribute search feeds. ';

const ParagraphVeracityAdsForAdvertisers: React.FC = () => (
  <TitleSeparatorTextComponent title="VeracityAds For Advertisers" text={veracityAdsForAdvertisersText}>
    <div id="learn-more-button-wrapper">
      <LearnMoreButton link="advertisers" />
    </div>
  </TitleSeparatorTextComponent>
);

export const VeracityAdsForAdvertisers: React.FC = () => (
  <div id="container-VeracityAdsForAdvertisers">
    <HorizontalSection
      left={<VeracityAdsForAdvertisersGraphic width="100%" />}
      right={<ParagraphVeracityAdsForAdvertisers />}
      reverseVerticalView
    />
  </div>
);
