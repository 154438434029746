import * as React from 'react';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import './Footer.scss';

export const Footer: React.FC = () => (
  <div id="footer">
    <div className="main-content">
      <div className="section">
        <h2>Sitemap</h2>
        <div className="footer-section-seperator" />
        <a href="publishers">Publishers</a>
        <a href="advertisers">Advertisers</a>
        <a href="products">Products</a>
        <a href="careers">Careers</a>
        <a href="fund">Fund</a>
        <a href="about">About</a>
        <a href="contact">Contact</a>
      </div>

      <div className="section">
        <h2>Products</h2>
        <div className="footer-section-seperator" />
        <a href="http://verifactsapp.com/">Verifacts</a>
        <a href="https://play.google.com/store/apps/details?id=com.apperzhome.showseek">ShowSeek</a>
        <a href="https://play.google.com/store/apps/details?id=com.apperzhome.moviematch">MovieMatch</a>
        <a href="https://chrome.google.com/webstore/detail/netflix-filter-flixer/pkldnahppnkkkhbgaleaheiagoidimek">
          Flixer
        </a>
        <a href="https://chrome.google.com/webstore/detail/new-tab-randomizer/acpfgnibllpmfdiimfeimgdapalbihgn">
          New Tab Randomizer
        </a>
      </div>

      <div className="section">
        <h2>Contact</h2>
        <div className="footer-section-seperator" />

        <div className="sub-section">
          <LocationCityIcon />
          <div className="text">
            <p>Office Address:</p>
            <p>Simtat HaYekev 4,</p>
            <p>Jerusalem,</p>
            <p>Israel, 9574762</p>
          </div>
        </div>

        <div className="sub-section">
          <EmailOutlinedIcon />
          <div className="text">
            <p>Contact Email:</p>
            <p>contact@veracityads.com</p>
          </div>
        </div>

        <p className="move-right"> Follow Us:</p>
        <div id="social-links" className="sub-section">
          <a className="move-right" href="https://www.linkedin.com/company/veracityads/">
            <LinkedInIcon fontSize="large" />
          </a>
          <a className="move-right" href="https://www.facebook.com/VeracityAds-108612674673481">
            <FacebookRoundedIcon fontSize="large" />
          </a>
        </div>
      </div>
    </div>

    <div className="main-seperator" />

    <div className="bottom-row">
      <p>Copyright © 2022 Linkular Ltd. All Rights Reserved</p>
      <div id="links">
        <a href="terms">
          {' '}
          <p>Terms</p>{' '}
        </a>

        <div className="privacy-terms-seperator" />

        <a href="privacy-policy">
          {' '}
          <p>Privacy Policy</p>{' '}
        </a>
      </div>
    </div>
  </div>
);
