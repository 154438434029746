import * as React from 'react';

import { HorizontalSection } from 'components/common/HorizontalSection/HorizontalSection';

import { TitleSeparatorTextComponent } from 'components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { ApplyButton } from 'components/common/buttons/ApplyButton';
import StatisticsAnalysis1 from './assets/images/statistics-analysis-1.svg';

export const Fund: React.FC = () => {
  const infoSection = (
    <TitleSeparatorTextComponent
      title="VeracityAds Fund"
      text={[
        "Our main purpose, here at VeracityAds, is to help improve the world. As part of that ambition, part of the company's profits are allocated to funding early-stage companies, visionary entrepreneurs, and groundbreaking startups whose mission is to bring about positive global change.",
        'If you are innovators or part of an exceptional founding team, please click Apply below, so we can start the process of working together to shape our collective future.',
      ]}
      titleSize="large"
    >
      <ApplyButton link="https://docs.google.com/forms/d/e/1FAIpQLSeYyUjEIFW2-4uAfBHCTGwoWM7pShhGxsXLkqSn2niAniPTNQ/viewform" />
    </TitleSeparatorTextComponent>
  );

  return (
    <div className="page">
      <div className="first-section">
        <HorizontalSection left={infoSection} right={<img src={StatisticsAnalysis1} />} />
      </div>
    </div>
  );
};
