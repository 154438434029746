import * as React from 'react';

import './WhyChooseVeracityAds.scss';

import { LearnMoreButton } from 'components/common/buttons/LearnMoreButton';
import { IconTitleText } from '../../../../components/common/IconTitleText/IconTitleText';

import { ReactComponent as BgElementWhyChooseVeracityAds } from '../../assets/bg-element-WhyChooseVeracityAds.svg';

import { ReactComponent as LinedCircleElement } from '../../../../components/common/SignUpTodayBanner/assets/element-lined-circle.svg';

import { ReactComponent as IconAutomatedAndOrganized } from '../../assets/icon-AutomatedAndOrganized.svg';

import { ReactComponent as IconCorporateGoal } from '../../assets/icon-CorporateGoal.svg';

import { ReactComponent as IconSuccessfulPartnership } from '../../assets/icon-SuccessfulPartnership.svg';

import { ReactComponent as IconVeracityTruthfulness } from '../../assets/icon-VeracityTruthfulness.svg';

import { NormalTitle } from '../../../../components/common/WebsiteTextTypes';

const corporateGoalText =
  'We aim to invest in startups around the world to help solve major world problems, including drinking water supply, food supply, and human-rights-related issues.';

const veracityTruthfulnessText =
  'We chose the name VeracityAds because “veracity” is about being truthful and accurate. Above all, we aim to be moral, and do good through honesty and respect.';

const automatedAndOrganizedText =
  'We have an automated reporting system with personal campaign and billing management. This enables our partners to benefit from quick action, fast reporting, and timely payments.';

const successfulPartnershipsText =
  'VeracityAds started in early 2021 and, together with our partners, we are already generating millions of dollars in search revenue every month. ';

export const WhyChooseVeracityAds: React.FC = () => {
  const iconWidthPercentage = '100%';
  return (
    <div id="container-WhyChooseVeracityAds">
      <div id="content-WhyChooseVeracityAds">
        <NormalTitle>Why Choose VeracityAds</NormalTitle>
        <div id="grid-WhyChooseVeracityAds">
          <IconTitleText title='Corporate Goal "Do Good"' text={corporateGoalText}>
            <a href="/fund">
              <IconCorporateGoal width={iconWidthPercentage} />
            </a>
          </IconTitleText>

          <IconTitleText title="Veracity = Truthfulness" text={veracityTruthfulnessText}>
            <IconVeracityTruthfulness width={iconWidthPercentage} />
          </IconTitleText>

          <IconTitleText title="Automated & Organized" text={automatedAndOrganizedText}>
            <IconAutomatedAndOrganized width={iconWidthPercentage} />
          </IconTitleText>

          <IconTitleText title="Successful Partnerships" text={successfulPartnershipsText}>
            <IconSuccessfulPartnership width={iconWidthPercentage} />
          </IconTitleText>
        </div>

        <div id="learn-more-button-wrapper">
          <LearnMoreButton link="about" />
        </div>

        <div id="bg-element-WhyChooseVeracityAds">
          <BgElementWhyChooseVeracityAds width="100%" />
        </div>
        <div id="lined-circle-element-WhyChooseVeracityAds">
          <LinedCircleElement width="100%" height="300px" />
        </div>
      </div>
    </div>
  );
};
