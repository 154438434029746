import * as React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { Publishers } from './pages/Publishers';
import { Advertisers } from './pages/Advertisers';
import { Products } from './pages/Products';
import { Fund } from './pages/Fund';
import { Careers } from './pages/Careers';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

import './App.scss';
import { theme } from './theme';

export const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <div id="app">
      <NavigationBar />
      <div id="page-content">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/publishers" element={<Publishers />} />
            <Route path="/advertisers" element={<Advertisers />} />
            <Route path="/products" element={<Products />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/fund" element={<Fund />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  </ThemeProvider>
);
