import * as React from 'react';

import './BoostGrowthAtEveryStage.scss';
import { IconTitleText } from '../../../../components/common/IconTitleText/IconTitleText';

import { ReactComponent as IconDiversityOfSearchFeeds } from '../../assets/IconDiversityOfSearchFeeds.svg';
import { ReactComponent as IconEasyCampaignManagement } from '../../assets/IconEasyCampaignManagement.svg';
import { ReactComponent as IconAutomatedDailyReports } from '../../assets/IconAutomatedDailyReports.svg';

import { ReactComponent as IconQuickResponse } from '../../assets/IconQuickResponse.svg';

export const BoostGrowthAtEveryStage: React.FC = () => {
  const iconWidthPercentage = '100%';
  return (
    <div className="section" id="container-BoostGrowthAtEveryStage">
      <div id="grid-BoostGrowthAtEveryStage">
        <IconTitleText
          title="Diversity of Search Feeds"
          text="We provide Yahoo, Bing, and arbitrage search feeds for browser add-ons, mobile apps,
            or D2S/arbitrage."
        >
          <IconDiversityOfSearchFeeds width={iconWidthPercentage} />
        </IconTitleText>

        <IconTitleText
          title="Easy Campaign Management"
          text="We developed various tools, enabling you to manage multiple campaigns with us, efficiently and
            accurately. "
        >
          <IconEasyCampaignManagement width={iconWidthPercentage} />
        </IconTitleText>

        <IconTitleText
          title="Automated DailyReports"
          text="Our distribution system provides automated reporting in various formats, with a unique configuration for each partner."
        >
          <IconAutomatedDailyReports width={iconWidthPercentage} />
        </IconTitleText>

        <IconTitleText
          title="Quick Response"
          text="Our dedicated team of experts and account managers is available to ensure that our cooperation flourishes and expands over time."
        >
          <IconQuickResponse width={iconWidthPercentage} />
        </IconTitleText>
      </div>
    </div>
  );
};
