import * as React from 'react';

import { ContactUsSignUpCombo } from 'components/common/buttons/ContactUsSignUpCombo/ContactUsSignUpCombo';
import { TitleSeparatorTextComponent } from '../../../components/common/TitleSeparatorTextComponent/TitleSeparatorTextComponent';
import { HorizontalSection } from '../../../components/common/HorizontalSection/HorizontalSection';

import { ReactComponent as DistributeYourSearchFeedsGraphic } from '../assets/graphic-DistributeYourSearchFeeds.svg';

const distributeYourSearchFeedsText =
  'VeracityAds distributes premium Bing, Yahoo, and non-branded search feeds. Contact us, or sign up right away, to start distributing your search feeds through our network.';

const ParagraphDistributeYourSearchFeeds: React.FC = () => (
  <TitleSeparatorTextComponent
    title="Distribute Your Search Feeds"
    text={distributeYourSearchFeedsText}
    titleSize="large"
  >
    <ContactUsSignUpCombo />
  </TitleSeparatorTextComponent>
);

export const DistributeYourSearchFeeds: React.FC = () => (
  <HorizontalSection
    left={<ParagraphDistributeYourSearchFeeds />}
    right={<DistributeYourSearchFeedsGraphic width="100%" />}
  />
);
