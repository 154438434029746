import 'react-app-polyfill/ie11';
import * as React from 'react';

import './ContactUsBanner.scss';
import { ReactComponent as DottedSquareElement } from '../../../assets/element-dot-square.svg';

import { VeracityAdsForm } from '../VeracityAdsForm/VeracityAdsForm';
import { ContactInfo } from './components/ContactInfo';
import { LargeTitle, NormalText } from '../WebsiteTextTypes';
import { ReactComponent as SendButtonImage } from '../buttons/images/send-button.svg';

const ContactUsBannerText =
  'Please contact us to start generating revenue from our search feeds distribution, or to propose other business opportunities, and means of cooperation.';

export const ContactUsBanner: React.FC = () => (
  <div id="container-ContactUsBanner">
    <div id="heading-and-text-container">
      <LargeTitle>Contact Us</LargeTitle>
      <NormalText>{ContactUsBannerText}</NormalText>
    </div>
    <div id="banner-ContactUs">
      <VeracityAdsForm type="contact" submitButton={<SendButtonImage width="100%" className="text-image-button" />} />
      <ContactInfo />
      <div id="contact-us-background-element-green" />
      <div id="contact-us-background-element-blue" />
      <div id="contact-us-element-lined-circle" />
      <DottedSquareElement id="contact-us-element-dotted-square" />
    </div>
  </div>
);
