import * as React from 'react';
import Button from '@mui/material/Button';

import './NavigationLinks.scss';
import { SignupModal } from '../../../common/SignupPopup/SignupPopup';

export const NavigationLinks: React.FC = () => (
  <div id="navigation-links-container">
    <a href="publishers">Publishers</a>
    <a href="advertisers">Advertisers</a>
    <a href="products">Products</a>
    <a href="careers">Careers</a>
    <a href="fund">Fund</a>
    <a href="about">About</a>
    <a href="contact">Contact</a>
    <Button
      variant="contained"
      sx={{
        marginLeft: '1vw',
        backgroundColor: { tablet: 'white' },
        color: { tablet: 'primary.main' },
      }}
    >
      <a>
        <SignupModal>Sign Up</SignupModal>
      </a>
    </Button>
  </div>
);
